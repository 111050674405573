/* eslint-disable react/prop-types */
import { useContext, useEffect, useState } from 'react';

import { Controller } from 'react-hook-form';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { UIContext } from '../../../../../context/UIContext';

import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers-pro';
import StepButtons from '../../../../../shared/StepButtons';
import DropDown from '../../../../../shared/DropDown';
import LeaseTermination from './LeaseTermination';
// TextInformation
import {
  DaysGuarantee,
  MoveOutDatePriorLeaseEndDate,
  MoveOutSameLeaseEndDate,
  MoveOuteGraterLeaseEndDate,
  RemainRentResponsible,
  Transfer
} from './TextLeaseTermination';

dayjs.extend(advancedFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(utc);
dayjs.extend(tz);

const MoveOutInformation = ({
  control,
  register,
  errors,
  leaseExpirationDate,
  disableForm,
  handleBack,
  setValue,
  handleStep,
  watch,
  setIsValidStep
}) => {
  const {
    formData,
    updateValidationForm,
    moveOutInformationRes,
    forwardingAddressRes,
    reasonToMoveOutRes,
    termsRes
  } = useContext(UIContext);

  const textIntialState = {
    moveOutEqual: false,
    transfer: false,
    moveOutGrater: false,
    remainRentResponsible: false,
    moveOutDatePrior: false,
    daysGuarantee: false
  };

  const [maxDate, setMaxDate] = useState();
  const [minDate, setMinDate] = useState();
  const [moveOutInfoForm, setMoveOutInfoForm] = useState({});
  const [displaLeaseTermination, setDisplayLeaseTermination] = useState(false);
  const [displayText, setDisplayText] = useState(textIntialState);

  const getRangesDates = (range, timeZone) => {
    let date = dayjs().tz(timeZone).startOf('day');

    if (range === 'max') {
      date = date.add(24, 'month');
    }

    return date.format('YYYY-MM-DD');
  };

  useEffect(() => {
    setMinDate(getRangesDates('min', formData?.communityTimeZone));
    setMaxDate(getRangesDates('max', formData?.communityTimeZone));
  }, [formData?.communityTimeZone]);

  const communityActions = (transferToAnotherCommunity, moveOutDate) => {
    const moveOutDateUtc = dayjs.utc(moveOutDate).startOf('day');
    const leaseEndDateUtc = dayjs.utc(leaseExpirationDate).startOf('day');

    const occupancyDateUtc = dayjs.utc(formData.lease.occupancyDate).startOf('day');
    const currentDateUtc = dayjs.utc().startOf('day');

    const isOccupancyWithin30Days = currentDateUtc.diff(occupancyDateUtc, 'day') <= 30;
    const isMoveoutWithin30Days = moveOutDateUtc.diff(currentDateUtc, 'day') <= 30;

    const hasEarlyTerminationFees = formData.earlyTermination.fees.length > 0;

    const moveOutBeforeLeaseEnd = moveOutDateUtc.isBefore(leaseEndDateUtc);
    const moveOutSameAsLeaseEnd = moveOutDateUtc.isSame(leaseEndDateUtc);
    const moveOutAfterLeaseEnd = moveOutDateUtc.isAfter(leaseEndDateUtc);

    const isDaysGuarantee = isOccupancyWithin30Days && isMoveoutWithin30Days;

    const defaultAction = () => {
      setDisplayText(textIntialState);
      setDisplayLeaseTermination(false);
    };
    setDisplayLeaseTermination(false);
    setDisplayText(textIntialState);

    const COMUNITY_ACTIONS = {
      1: () => {
        if (moveOutBeforeLeaseEnd) {
          setDisplayText(textIntialState);
          if (isDaysGuarantee) {
            return setDisplayText({ ...textIntialState, daysGuarantee: true });
          }
          if (hasEarlyTerminationFees) {
            if (!formData?.earlyTermination?.fees.some((item) => item.fee > 0)) {
              setDisplayText({ ...textIntialState, remainRentResponsible: true });
              setDisplayLeaseTermination(false);
            } else {
              setDisplayLeaseTermination(hasEarlyTerminationFees);
            }
          }
          if (!hasEarlyTerminationFees) {
            if (formData?.isEarlyTerm) {
              setDisplayText({ ...textIntialState, remainRentResponsible: true });
            } else {
              setDisplayText({ ...textIntialState, moveOutDatePrior: true });
            }
          }
        } else if (isDaysGuarantee) {
          setDisplayText({ ...textIntialState, daysGuarantee: true });
        } else if (moveOutSameAsLeaseEnd) {
          setDisplayText({ ...textIntialState, moveOutEqual: true });
        } else if (moveOutAfterLeaseEnd) {
          setDisplayText({ ...textIntialState, moveOutGrater: true });
        } else if (formData?.isEarlyTerm && !hasEarlyTerminationFees) {
          setDisplayText({ ...textIntialState, remainRentResponsible: true });
          setDisplayLeaseTermination(false);
        } else {
          defaultAction();
        }
      },
      2: () => {
        setDisplayText({ ...textIntialState, transfer: true });
      },
      3: () => {
        setDisplayText({ ...textIntialState, transfer: true });
      }
    };
    if (moveOutInfoForm?.transferToAnotherCommunity) {
      return (COMUNITY_ACTIONS[moveOutInfoForm.transferToAnotherCommunity?.id] || defaultAction)();
    } else {
      return (COMUNITY_ACTIONS[transferToAnotherCommunity?.id] || defaultAction)();
    }
  };

  const updateForm = (data) => {
    const {
      moveOutDate,
      transferToAnotherCommunity,
      leaseTerminationOption,
      agreementToSubmitAnEarlieTerminationPayment,
      confirmBalanceInAccount
    } = data;

    setMoveOutInfoForm({
      moveOutDate,
      transferToAnotherCommunity,
      leaseTerminationOption,
      agreementToSubmitAnEarlieTerminationPayment,
      confirmBalanceInAccount
    });

    updateValidationForm({
      forwardingAddressRes,
      reasonToMoveOutRes,
      termsRes,
      moveOutInformationRes: {
        selectedFee: moveOutInformationRes?.selectedFee,
        ...data
      }
    });

    communityActions(transferToAnotherCommunity, moveOutDate);
  };
  const validateBtn = () => {
    const {
      moveOutDate,
      transferToAnotherCommunity,
      leaseTerminationOption,
      agreementToSubmitAnEarlieTerminationPayment,
      confirmBalanceInAccount
    } = moveOutInfoForm;

    function validateAgreementAndBalance(agreement, balance) {
      return (
        (agreement === true || agreement === 'true') && (balance === true || balance === 'true')
      );
    }

    function isValidEarlyTermination(fees) {
      return fees.length > 0 && fees.some((item) => item.fee > 0);
    }

    let isValidStep =
      !!moveOutDate &&
      typeof moveOutDate === 'object' &&
      !!transferToAnotherCommunity &&
      typeof transferToAnotherCommunity === 'object';

    if (displaLeaseTermination && isValidEarlyTermination(formData?.earlyTermination?.fees)) {
      isValidStep = false;

      if (transferToAnotherCommunity?.id === 1) {
        if (leaseTerminationOption?.id === 1) {
          isValidStep = validateAgreementAndBalance(
            agreementToSubmitAnEarlieTerminationPayment,
            confirmBalanceInAccount
          );
        } else if (leaseTerminationOption?.id === 2) {
          isValidStep = true;
        }
      } else {
        isValidStep = true;
      }
    }

    setIsValidStep(isValidStep);
    return isValidStep;
  };

  useEffect(() => {
    const subscription = watch((data) => {
      const updatedData = {
        ...moveOutInformationRes,
        ...forwardingAddressRes,
        ...reasonToMoveOutRes,
        ...termsRes,
        ...Object.keys(data).reduce((acc, key) => {
          if (data[key] !== undefined || typeof data[key] === 'object') {
            acc[key] = data[key];
          }
          return acc;
        }, {})
      };

      updateForm(updatedData);
    });

    moveOutInformationRes?.transferToAnotherCommunity,
      moveOutInformationRes?.moveOutDate &&
        communityActions(
          moveOutInformationRes.transferToAnotherCommunity,
          moveOutInformationRes.moveOutDate
        );

    return () => {
      subscription.unsubscribe();
    };
  }, [watch, moveOutInformationRes]);

  const prevState = () => {
    communityActions(
      moveOutInformationRes.transferToAnotherCommunity,
      moveOutInformationRes.moveOutDate
    );
    setMoveOutInfoForm(moveOutInformationRes);
  };

  useEffect(() => {
    moveOutInformationRes && prevState();
  }, []);

  return (
    <div className="bg-white  rounded w-11/12 lg:m-10 m-3 md:m-6 ">
      <div className="font-proxima">
        <p className="font-proxima  text-sm font-semibold mb-1 md:text-[24px] md:font-medium">
          Move-Out Information
        </p>
        <div className="text-tiny md:text-sm md:pt-2">
          Please note that when you select your preferred move-out date, the form will show you the
          available options. If you choose a different move-out date, the form will update
          accordingly.
        </div>
        <div className="mt-5">
          <div className="md:w-1/2 text-xs  md:text-sm ">
            <p className="font-semibold">Lease-End Date</p>
            <p className="md:mt-2 text-[#525761]">
              {dayjs(leaseExpirationDate).format('MM/DD/YYYY')}
            </p>
          </div>
          <div className=" mt-5 md:mt-5 relative md:w-[32.5%]">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              <p className=" font-semibold text-xs md:text-sm">
                <span className="text-[#F5222D]">*</span> Move-Out Date
              </p>
            </label>
            <Controller
              control={control}
              name="moveOutDate"
              placeholder={'mm/dd/yy'}
              rules={{
                required: true,
                validate: {
                  min: (date) =>
                    dayjs(date).isSameOrAfter(dayjs(minDate)) ||
                    'Please select a valid move-out date',
                  max: (date) =>
                    dayjs(date).isSameOrBefore(dayjs(maxDate)) ||
                    'Please select a valid move-out date'
                }
              }}
              render={({ field: { onChange, ref, value } }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    id="moveOutDatePicker"
                    name="moveOutDatePicker"
                    disablePast
                    value={value || null}
                    inputRef={ref}
                    onChange={(newValue) => {
                      onChange(newValue);
                    }}
                    maxDate={dayjs(maxDate)}
                    shouldDisableYear={() => false}
                    className={
                      errors.moveOutDate
                        ? 'moveOutDatePicker error-input border-[#F5222D] border rounded w-full py-2 px-3 md:w-11/12 text-gray-700 leading-tight focus:outline-none focus:shadow-outline  cursor-pointer'
                        : 'moveOutDatePicker border border-[#BABCC0]  rounded w-full py-2 px-3 md:w-11/12 text-gray-700 leading-tight focus:outline-none focus:shadow-outline disabled:bg-[#BABCC067]  disabled:text-[#979AA0] disabled:italic disabled:border-slate-200 disabled:shadow-none cursor-pointer'
                    }
                  />
                </LocalizationProvider>
              )}
            />
            {errors?.moveOutDate && (
              <p className="text-[#F5222D] text-[10px] pt-2">Please select a valid move-out date</p>
            )}
            <div
              className={
                disableForm
                  ? 'absolute right-[5.4%] top-[37px]  md:right-[12.4%] md:top-[40px] cursor-pointer  lg:right-[11.4%] lg:top-[38px] pointer-events-none  bg:[#BABCC067]  '
                  : 'absolute right-[5.4%] top-[37px]  md:right-[12.4%] md:top-[40px] cursor-pointer  lg:right-[11.4%] lg:top-[38px] pointer-events-none bg-white  '
              }></div>
          </div>
          <div>
            <div className="md:w-1/2 mt-5 md:mt-5">
              <label className="block text-sm font-bold mb-2">
                <p className="font-semibold text-xs md:text-sm">
                  <span className="text-[#F5222D] ">*</span> Are you planning to transfer to another
                  AvalonBay Community?
                </p>
              </label>
              <div className="w-[100%]">
                <Controller
                  control={control}
                  name="transferToAnotherCommunity"
                  defaultValue=""
                  rules={{
                    required: true
                  }}
                  render={({ field: { onChange, value } }) => (
                    <DropDown
                      onChange={(e) => {
                        onChange(e);
                      }}
                      name={'name'}
                      value={value}
                      errors={errors}
                      errorName={'transferToAnotherCommunity'}
                      data={formData.transferOptions}
                      disableForm={disableForm}
                    />
                  )}
                />
              </div>
              {errors?.transferToAnotherCommunity && (
                <p className="text-[#F5222D] text-[10px] pt-2">Please select one option</p>
              )}
            </div>
          </div>
          <div>
            {moveOutInformationRes?.transferToAnotherCommunity &&
              moveOutInformationRes?.moveOutDate &&
              formData?.status === 'completed' && (
                <p className="text-[#C64D5B] font-bold text-xs md:text-sm pt-5 pb-2 leading-[24px] ">
                  Our records show that you have already renewed your lease. The information below
                  applies to your current lease and its move-out terms. Additional charges may apply
                  to cancel your renewal lease, subject to applicable law. <br /> <br />
                  To understand your financial obligations related to your renewal, please reach out
                  to an associate for assistance before submitting this form.
                </p>
              )}
          </div>
        </div>
        {displaLeaseTermination &&
          formData?.earlyTermination?.fees?.length &&
          formData?.earlyTermination?.fees?.some((item) => item.fee > 0) && (
            <LeaseTermination
              register={register}
              errors={errors}
              control={control}
              leaseExpirationDate={leaseExpirationDate}
              disableForm={disableForm}
              setValue={setValue}
              moveOutSelection={moveOutInfoForm.moveOutDate}
              watch={watch}
            />
          )}

        {displayText.remainRentResponsible &&
          RemainRentResponsible(moveOutInfoForm?.moveOutDate, leaseExpirationDate)}
        {displayText.daysGuarantee && DaysGuarantee(formData?.lease?.occupancyDate)}
        {displayText.moveOutDatePrior && MoveOutDatePriorLeaseEndDate()}
        {displayText.moveOutEqual &&
          MoveOutSameLeaseEndDate(
            formData?.blockMtm,
            formData?.lease?.noticeToVacate,
            moveOutInfoForm.moveOutDate.diff(
              dayjs().tz(formData?.communityTimeZone).startOf('day'),
              'days'
            )
          )}
        {displayText.moveOutGrater &&
          MoveOuteGraterLeaseEndDate(
            formData?.blockMtm,
            formData?.lease?.noticeToVacate,
            moveOutInfoForm.moveOutDate.diff(
              dayjs().tz(formData?.communityTimeZone).startOf('day'),
              'days'
            ),
            formData?.lease?.leaseTerm,
            formData?.lease?.leaseExpirationDate
          )}
        {displayText.transfer && Transfer()}
      </div>
      <div className="xs:invisible md:visible">
        <StepButtons
          isValidForm={validateBtn()}
          handleBack={handleBack}
          handleStep={handleStep}
          cancelText="Previous"
          confirmText="Next"
        />
      </div>
    </div>
  );
};

export default MoveOutInformation;
