/* eslint-disable no-undef */
import MoveOut_icon from '../../../../../assets/img/moveout-icon.png';
import moveOut_icon_white from '../../../../../assets/img/kanso_moving-box.png';
import { useEffect } from 'react';

const NoteInformation = ({
  community,
  residentsCoResidentsOccupants,
  handleStep,
  setIsValidStep
}) => {
  useEffect(() => {
    setIsValidStep(true);
  }, []);
  return (
    <div className="p-6 xs:h-[510px] md:h-[465px] ">
      {' '}
      <div className=" pb-2 pt-2 justify-around items-center lg:flex  ">
        <div>
          <img
            src={community?.brand === 'kanso' ? moveOut_icon_white : MoveOut_icon}
            className="w-[24px] lg:w-[30px] float-left "
            alt="moveOut_Icon"
          />
        </div>
        <div className="w-full  text-center  lg:flex  lg:justify-between">
          <div>
            <p
              className={
                community?.brand === 'ava'
                  ? 'font-costa text-base pl-5 mr-5  lg:text-header text-headerFormColor'
                  : 'font-utopia text-base pl-5 mr-5  lg:text-header text-headerFormColor'
              }>
              Notice To Vacate Form
            </p>
          </div>
          <div className="lg:flex lg:items-center lg:pr-2">
            <p className="font-proxima text-tiny lg:text-subHeader font-bold text-[#E65100]">
              Impacts all Occupants ({residentsCoResidentsOccupants})
            </p>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <p className="font-proxima font-bold text-[#31343A] text-xs lg:text-base md:text-sm break-words">
          If there are multiple residents on the lease, and not all residents are moving out, do not
          complete this form. Instead, return to the dashboard and submit a {''}
          <br class="hidden md:block" />
          <span>
            <a
              href={`${process.env.REACT_APP_RENEWALS_URL}/dashboard`}
              className="text-[#0069CB] underline">
              Lease Help Request Form on Dashboard
            </a>
          </span>{' '}
          for an occupancy change, or contact an associate for assistance.
        </p>

        <br />
        <br />

        <p className="font-proxima font-normal text-[#31343A] text-tiny lg:text-sm md:text-xs">
          <span className="font-bold">Please Note:</span> Your lease requires you to provide us with
          notice of your intent to vacate. A copy of this form will be sent to the community, in
          addition to all residents’ emails listed. By submitting this form, you confirm that you
          are submitting on behalf of all residents identified in the lease for this apartment home
          and indemnify AvalonBay for any related liability.
        </p>
      </div>
      <div>
        <div className="flex justify-around md:justify-end mt-5 font-proxima font-semibold xs:text-xs md:text-sm  lg:mt-5 mb-5 ">
          <div className="md:mr-5">
            <a href={`${process.env.REACT_APP_RENEWALS_URL}/dashboard`}>
              <button
                type="button"
                className="bg-white w-[auto] h-[44px] lg:w-[auto] lg:h-[40px]  hover:border-primary hover:text-primary">
                Go back to Dashboard
              </button>
            </a>
          </div>
          <button
            className="btn-bg hover:bg-hover w-[120px] h-[44px] lg:w-[178px] lg:h-[40px] text-contrast rounded-md"
            onClick={handleStep}
            aria-label="submitBtn">
            I understand
          </button>
        </div>
      </div>
    </div>
  );
};

export default NoteInformation;
