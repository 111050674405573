import React, { useCallback, useState } from 'react';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import MainInfoBuilding from './occupantDetails/MainInfoBuilding';
import OccupantDetails from './occupantDetails/OccupantDetails';
import MoveOutInformation from './moveOutInfo/MoveOutInformation';
import ForwardingAddress from './forwardingAddress/ForwardingAddress';
import TermsAndAgreement from './termsAgreement/TermsAndAgreement';
import NoteInformation from './intro/NoteInformation';
import ReasonToMoveOut from './reasonToMoveOut/ReasonToMoveOut';
import clsx from 'clsx';
//mobile
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';

import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';

const steps = [
  'Intro',
  'Occupant(s) Details',
  'Move-Out Information',
  'Reason to Move-Out',
  'Forwarding Address',
  'Terms & Agreement'
];

function StepperContainer({
  community,
  residentsCoResidentsOccupants,
  coresidents,
  register,
  errors,
  control,
  setValue,
  leaseExpirationDate,
  disableForm,
  resetField,
  reset,
  clearErrors,
  watch,
  dirtyFields,
  validForm,
  isOpen,
  handleCurrentStep,
  scrollToTop,
  onSubmit
}) {
  const [activeStep, setActiveStep] = useState(0);
  const [maxSteps] = useState(6);
  const theme = useTheme();
  const [isValidStep, setIsValidStep] = useState(true);

  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => {
      const newActiveStep = prevActiveStep + 1;
      handleCurrentStep(prevActiveStep + 1);
      scrollToTop();
      return newActiveStep;
    });
  }, []);

  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => {
      const newActiveStep = prevActiveStep - 1;
      handleCurrentStep(newActiveStep);
      scrollToTop();
      return newActiveStep;
    });
  }, []);
  const styles = {
    divClass:
      'bg-white border rounded  xs:mt-5 lg:mt-5  lg:w-[100%] flex-col content-center justify-items-center justify-center items-center '
  };

  return (
    <>
      <Box sx={{ width: '100%', display: 'flex' }} className="xs:flex-col md:flex-row">
        <div className="xs:hidden md:flex md:justify-center md:items-start md:pr-[2%] md:pt-[10%] md:pl-[0%] lg:pl-[5%] lg:block lg:pt-0">
          <Stepper
            activeStep={activeStep}
            sx={{
              margin: '2% 1%',
              height: '250px',
              '& .MuiStep-root': {
                '& .MuiStepLabel-root': {
                  '& .MuiStepIcon-root': {
                    // Use clsx to apply Tailwind classes conditionally
                    '&.Mui-active': {
                      color: 'var(--tw-text-primary)' // Custom property for primary color
                    },
                    '&.Mui-completed': {
                      color: 'var(--tw-text-primary)' // Custom property for primary color
                    }
                  },
                  '& .MuiStepLabel-label': {
                    // Use clsx to apply Tailwind classes conditionally
                    '&.Mui-active': {
                      color: 'var(--tw-text-primary)' // Custom property for primary color
                    },
                    '&.Mui-completed': {
                      color: 'var(--tw-text-primary)' // Custom property for primary color
                    }
                  }
                }
              }
            }}
            orientation="vertical">
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepLabel
                    className={clsx({
                      'text-primary': activeStep === index || activeStep > index
                    })}
                    {...labelProps}
                    sx={{ fontSize: '14px' }}>
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </div>

        {
          <div className={`${styles.divClass} ${isOpen ? 'md:mt-[5%]' : 'md:mt-[10%] '}  `}>
            {activeStep === 0 && (
              <NoteInformation
                community={community}
                residentsCoResidentsOccupants={residentsCoResidentsOccupants}
                handleStep={handleNext}
                setIsValidStep={setIsValidStep}
              />
            )}
            {activeStep === 1 && (
              <Box>
                <MainInfoBuilding />
                {coresidents && (
                  <OccupantDetails
                    coresidents={coresidents}
                    handleStep={handleNext}
                    handleBack={handleBack}
                    setIsValidStep={setIsValidStep}
                  />
                )}
              </Box>
            )}
            {activeStep === 2 && (
              <>
                <div className="lg:w-[250px]"></div>
                <MoveOutInformation
                  register={register}
                  resetField={resetField}
                  errors={errors}
                  control={control}
                  setValue={setValue}
                  leaseExpirationDate={leaseExpirationDate}
                  disableForm={disableForm}
                  handleStep={handleNext}
                  handleBack={handleBack}
                  watch={watch}
                  setIsValidStep={setIsValidStep}
                />
              </>
            )}
            {activeStep === 3 && (
              <ReasonToMoveOut
                control={control}
                errors={errors}
                disableForm={disableForm}
                register={register}
                handleStep={handleNext}
                handleBack={handleBack}
                watch={watch}
                setIsValidStep={setIsValidStep}
              />
            )}
            {activeStep === 4 && (
              <ForwardingAddress
                resetField={resetField}
                register={register}
                errors={errors}
                reset={reset}
                clearErrors={clearErrors}
                disableForm={disableForm}
                watch={watch}
                setValue={setValue}
                dirtyFields={dirtyFields}
                control={control}
                validForm={validForm}
                handleStep={handleNext}
                handleBack={handleBack}
                setIsValidStep={setIsValidStep}
              />
            )}
            {activeStep === 5 && (
              <TermsAndAgreement
                resetField={resetField}
                register={register}
                errors={errors}
                reset={reset}
                clearErrors={clearErrors}
                disableForm={disableForm}
                setValue={setValue}
                handleBack={handleBack}
                handleSubmit={onSubmit}
                watch={watch}
                setIsValidStep={setIsValidStep}
              />
            )}
          </div>
        }
        <div className="xs:block md:hidden xs:pt-[5%]">
          <MobileStepper
            variant="text"
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
              activeStep !== 5 ? (
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1 || !isValidStep}>
                  <p
                    className={`${isValidStep ? 'text-primary' : 'text-gray-500'}  font-proxima capitalize text-xs`}>
                    Next
                  </p>
                  {theme.direction === 'rtl' ? (
                    <ChevronLeftIcon />
                  ) : (
                    <ChevronRightIcon className="w-4 text-black" />
                  )}
                </Button>
              ) : (
                <Button disabled={true}></Button>
              )
            }
            backButton={
              activeStep !== 0 ? (
                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                  {theme.direction === 'rtl' ? (
                    <ChevronRightIcon className="w-4 text-black" />
                  ) : (
                    <ChevronLeftIcon className="w-4 text-black" />
                  )}
                  <p className="text-primary text-xs font-proxima capitalize">Prev</p>
                </Button>
              ) : (
                <Button disabled={true}></Button>
              )
            }
          />
        </div>
      </Box>
    </>
  );
}

export default StepperContainer;
